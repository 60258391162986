import Head from "next/head";
import React from "react";

interface Props {
  title: string;
  /** OG description */
  description: string;
  /** OG URL */
  url: string;
  image?: string;
}

export default function Metadata(props: Props) {
  const { title, description, url, image } = props;

  return (
    <Head key={url}>
      <title>{title}</title>
      <meta property="og:title" content={title} />

      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      {!!image && <meta property="og:image" content={image} />}
    </Head>
  );
}
